import request from "./http"


/**
 * @description 获取店铺数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 返回店铺数据
 */
export function getStore(limit, page, data) {
    return request({
        url: `/api/admin/store/list?limit=${limit}&page=${page}`,
        method: "post",
        data
    })
}


/**
 * @description 新增店铺
 *  @param {Object} data 表单数据
 * @return 新增成功
 */
export function addStore(data) {
    return request({
        url: "/api/admin/store/create",
        method: "post",
        data
    })
}


/**
 * @description 删除店铺
 *  @param {String} id 店铺id
 * @return 
 */
export function delStore(id) {
    return request({
        url: `/api/admin/store/delete/${id}`,
        method: "post",
    })
}



/**
 * @description 修改店铺
 *  @param {String} id 店铺id
 *  @param {Array} data 表单数据
 * @return 
 */
 export function updateStore(id,data) {
    return request({
        url: `/api/admin/store/update/${id}`,
        method: "post",
        data
    })
}