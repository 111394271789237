<!--
* @FileDescription: 门店管理
* @Author: chenz
* @Date: 2021/4/28
 * @LastEditors: chenz
 * @LastEditTime: 2021-11-18 10:54:21
-->
<template>
  <div class="store-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @handleAvatarSuccess="handleAvatarSuccess"
      @handleLogoSuccess="handleLogoSuccess"
      @handleRemove="handleRemove(arguments)"
      @handleSuccess="handleSuccess(arguments)"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import {
  getStore,
  addStore,
  delStore,
  updateStore,
} from "../../api/store-manage"

import { updateUser } from "../../api/user"

// 引入方法
import {
  getExcelLabelOrProp,
  getPhoneValidator,
  getIDCardValidator,
} from "../../utils/tool"

import http from "../../api/http"

export default {
  name: "StoreManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        name: "",
      },

      // 查询表单项
      queryFormItemList: [
        { type: "input", prop: "name", placeholder: "请输入要查询的名称" },
      ],

      // 列接受的参数
      columns: [
        { label: "店铺名称", prop: "name", width: 120 },
        { label: "简介", prop: "introduction", showOverFlowTooltip: true },
        { label: "地址", prop: "address", width: 200 },
        {
          label: "详细地址",
          prop: "detailAddress",
          width: 120,
          showOverFlowTooltip: true,
        },
        { label: "等级", prop: "level", width: 120 },
        { label: "营业时间", prop: "businessTime" },
        { label: "联系电话", prop: "contactPhone", width: 120 },
        { label: "创建时间", prop: "createTime", width: 120 },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加店铺",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        name: "",
        introduction: "",
        address: "",
        detailAddress: "",
        level: "",
        businessTime: "",
        contactPhone: "",
        realName: "",
        avatar: "",
        avatars: "",
        image: "",
        images: "",
        idCard: "",
        phone: "",
        logo: [],
        imagesInfos: [],
        bannerMapList: [],
      },

      // 校验
      rules: {
        name: [{ required: true, message: "店铺不能为空", trigger: "change" }],
        introduction: [
          { required: true, message: "简介不能为空", trigger: "change" },
        ],
        address: [
          { required: true, message: "地址不能为空", trigger: "change" },
        ],
        detailAddress: [
          { required: true, message: "详细地址不能为空", trigger: "change" },
        ],
        level: [{ required: true, message: "等级不能为空", trigger: "change" }],
        businessTime: [
          { required: true, message: "营业时间不能为空", trigger: "change" },
        ],
        contactPhone: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        realName: [
          { required: true, message: "真实姓名不能为空", trigger: "change" },
        ],
        avatar: [
          { required: true, message: "用户头像不能为空", trigger: "change" },
        ],
        image: [
          { required: true, message: "店铺logo不能为空", trigger: "change" },
        ],
        // imagesInfos: [
        //   { required: true, message: "店铺轮播图不能为空", trigger: "change" },
        // ],
        idCard: [
          {
            required: true,
            validator: getIDCardValidator,
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            validator: getPhoneValidator,
            trigger: "change",
          },
        ],
      },

      // 表单数据
      formItemList: formItemList,

      baseURL: "",
    }
  },

  mounted() {
    this.baseURL = ""
    // this.baseURL = http.defaults.baseURL
    this.getData()
  },

  methods: {
    // 查询
    _query(form) {
      this.getData()
    },

    // 添加
    _add() {
      console.log("添加", this.form)
      this.visible = true
      this.dialogTitle = "添加店铺"
      this.form = {}
      this.$nextTick(() => {
        this.form.avatar = ""
        this.$nextTick(() => {
          // console.log(222222, this.$refs.form.$refs)
          // 每次新增清空照片
          this.$refs.form.$refs.uploadAvatar[0].clearFiles()
          this.$refs.form.$refs.uploadPhotoList[0].clearFiles()
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 导出
    _export(form) {
      // console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        console.log(
          "_export",
          this.selectChangeTableData.length,
          this.selectChangeTableData
        )
        console.log("this.tableData", this.tableData)
        console.log("list", list)
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "店铺管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
      console.log("选择要导出的", this.selectChangeTableData)
    },

    // 修改
    _edit(row) {
      console.log("修改", row)
      this.dialogTitle = "修改店铺"
      this.visible = true
      this.form = Object.assign({}, row)
      const item = this.formItemList.find((_) => _.prop === "imagesInfos")
      item.fileList = row.bannerMapList
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 删除
    async _del(row) {
      this.$confirm("你确定要删除此门店吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delStore(row.id)
          if (res.code !== 200) {
            return this.$message({ message: "删除失败", type: "error" })
          }
          this.$forceUpdate()
          this.$message({
            type: "success",
            message: "删除成功!",
          })
          this.getData()
          this.$forceUpdate()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      console.log("确定", this.form)
      this.$refs.form.$refs.form.validate(async (valid) => {
        if (valid) {
          this.dialogTitle === "添加店铺" ? this.add() : this.edit()
        } else {
          return false
        }
      })
    },

    // 添加接口
    async add() {
      try {
        const res = await addStore(this.form)
        console.log("添加接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "添加店铺失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "添加店铺成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {
        this.visible = false
        this.$message({
          showClose: true,
          message: "添加店铺失败",
          type: "error",
        })
      }
    },

    // 修改接口
    async edit() {
      try {
        const res = await updateStore(this.form.id, this.form)
        // console.log("修改接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "修改店铺失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "修改店铺成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {}
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
      this.visible = false // 关闭弹框
    },

    // 获取店铺数据
    async getData() {
      this.loading = true
      const { limit, page } = this.pagingOptions
      try {
        const res = await getStore(limit, page, {
          name: this.queryForm.name,
        })
        this.loading = false
        this.tableData = res.data.list
        // this.tableData.forEach((_) => {
        //     const imageUrlArr = _.image && _.image.split("/").splice(-3).join("/")
        // 回显图片
        // _.avatar = this.baseURL + _.avatar
        // _.avatar = "" + _.avatar
        // _.image = this.baseURL + `/${imageUrlArr}`
        // _.image = "" + `/${imageUrlArr}`
        // })
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    /**
     * @description 上传头像
     *  @param {Object} file 文件
     */
    handleAvatarSuccess(file) {
      // this.form.avatar = http.defaults.baseURL + file.response
      this.form.avatar = file.response
      this.form.avatars = file.response
    },

    /**
     * @description 上传logo
     *  @param {Object} file 文件
     */
    handleLogoSuccess(file) {
      // this.form.image = http.defaults.baseURL + file.response
      this.form.image = file.response
      this.form.images = file.response
    },

    /**
     * @description 上传轮播图
     *  @param {Object} options 文件
     */
    handleRemove(arg) {
      // console.log("arguments", ...arg)
      console.log("arg[1]", arg[1])
      const list = arg[1]
      const arr = []
      // list.forEach((_) => {
      //     const imageUrl = _.url.split("/").splice(-3).join("/")
      //     arr.push(`/${imageUrl}`)
      // })
      this.form.bannerMapList = arg[1]
      this.form.imagesInfos = JSON.stringify(arr)
      console.log(JSON.stringify(arr))
      console.log("this.form.bannerMapList", this.form.bannerMapList)
      console.log("this.form.imagesInfos", this.form.imagesInfos)
      // console.log("上传轮播图", ...e)
    },

    /**
     * @description 上传轮播图成功事件
     *  @param {Object} options 文件
     */
    handleSuccess(arg) {
      console.log(arg)
      const list = arg[2].map((_) => _.response || _.url)
      const arr = []
      for (let i = 0; i < arg[2].length; i++) {
        var obj = {}
        obj.name = arg[2][i].name
        // obj.url = arg[2][i].response || (arg[2][i].url.indexOf("blob")!=-1?arg[2][i].url:"")
        obj.url = arg[2][i].response || arg[2][i].url
        arr.push(obj)
      }
      this.form.bannerMapList = arr
      this.form.imagesInfos = list
    },
  },
}
</script>

<style lang="scss" scoped>
.store-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>